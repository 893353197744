// MEMO: 複合列舉的寫法 
// https://stackovercontext.com/a/51498560/2252696

import { IMember } from '@/api/members'
import { useMember } from '@/composables/member/useMember'
import { TradeAction, TradeOperation, getTradeAction } from './TradeAction'
import { ITrade } from './types'

type TradeType = 'SBS' | 'RDM' | 'SWITCH'

export enum TradeStatusCommon {
  /** 草稿 */
  Draft = 'draft',
  /** 已刪除 */
  Deleted = 'deleted',
  /** 保銀已下單 */
  FaxSent = 'fax_sent',
  /** 已遞件 */
  Delivered = 'delivered',
  /** 重新遞件 */
  Redelivered = 'redelivered',
  /** 修訂確認 (虛擬狀態，重新遞件被確認的下一個狀態) */
  RedeliverConfirmed = 'redeliver_confirmed',
  /** 線上審核通過 */
  ReviewPassed = 'review_passed',
  /** 退件：電子申請退件 */
  ApplyRejected = 'apply_rejected',
  /** 請寄送正本 */
  FileWaiting = 'file_waiting',
  /** 退件：紙本申請退件  */
  FileRejected = 'file_rejected',
  /** 已收到正本 */
  FileReceived = 'file_received',
  /** 已完成 */
  Finished = 'finished',
  /** 註銷申請 */
  CancelConfirm = 'cancel_confirm',
  /** 已註銷 */
  Cancelled = 'cancelled',
}

export enum TradeStatusSubscribe {
  /** 已到款 */
  CashReceived = 'cash_received',
}

export enum TradeStatusRedeem {
  /** 贖回款發放通知 */
  RefundWaiting = 'refund_waiting',
}

export enum SpecVersion {
  v221 = 221,
  v222 = 222,
}

const DefaultSpecVersion = SpecVersion.v222

/**
 * 交易狀態
 */
const TradeStatus = {
  ...TradeStatusCommon,
  ...TradeStatusSubscribe,
  ...TradeStatusRedeem,
}

type TradeStatus = TradeStatusCommon | TradeStatusSubscribe | TradeStatusRedeem

export { TradeStatus }

interface TradeStatusDefinition {
  /** 狀態識別碼 */
  code: TradeStatus
  /** 此狀態適用於交易類型 */
  context: TradeType[]
  /** 狀態名稱 */
  name: string
  /** 狀態代表圖示 */
  icon?: string
  /** 狀態代表顏色 */
  color?: string
  /** 檢視角度的顯示：Agent */
  displayAgent: DisplayBag | null
  /** 檢視角度的顯示：Holder */
  displayHolder: DisplayBag | null
  /** 檢視角度的顯示：Dealing Team */
  displayDealing: DisplayBag | null
  /** 此狀態下的主操作 */
  action?: TransformBag | null
  /** 此狀態下可否退件 */
  returnable?: TransformBag | null
  /** 此狀態下可否編輯 */
  editable?: TransformBag | null
  /** 此狀態下可否刪除 */
  deletable?: TransformBag | null
  /** 此狀態下可註銷 */
  cancelable?: TransformBag | null
  /** 此狀態下已完成的操作 */
  doneActions?: (TradeAction | TradeOperation)[] | 'ALL' | null
  /** 此狀態下完全禁止的操作 */
  lockActions?: (TradeAction | TradeOperation)[] | 'ALL' | null
  /** 此流程的版本 */
  version: SpecVersion
}

export interface TradeStatusDisplay extends TradeStatusDefinition, DisplayBag {
  /** 狀態顯示名稱 */
  name: string
  /** 狀態代表圖示 */
  icon: string
  /** 狀態代表顏色 */
  color: string
}

export interface TradeStatusFilterOption {
  name: string
  code: string
}

interface DisplayBag {
  /** 狀態顯示名稱 */
  name: string
  /** 狀態代表圖示 */
  icon: string
  /** 狀態代表顏色 */
  color: string
  /** 狀態是否於紀錄中隱藏 */
  hidden: boolean
}

interface TransformBag {
  action: TradeAction | TradeOperation,
  targetStatus: TransformableStatus,
  waits?: (trade: ITrade) => any,
}

/**
 * 可轉換的目標狀態
 * PREVIOUS_STATUS 代表退件前的狀態
 */
type TransformableStatus = TradeStatus | 'PREVIOUS_STATUS'

const CanReturnOrCancel = {
  returnable: defineTransform(TradeOperation.Reject, TradeStatus.ApplyRejected), // 可退件：電子申請退件,
  cancelable: defineTransform(TradeOperation.Cancel, TradeStatus.CancelConfirm), // Dealing 可註銷，需正本
}

const NoReturn = {
  returnable: null,
  cancelable: defineTransform(TradeOperation.Cancel, TradeStatus.CancelConfirm), // Dealing 可註銷，需正本
}

const WaitCashReceive = (trade: ITrade) => !!trade.file_cash_receipt
const WaitContractNote = (trade: ITrade) => !!trade.file_contract_note

// 退件流程
const TradeStatusReturns: TradeStatusDefinition[] = [
  {
    code: TradeStatus.ApplyRejected,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '電子申請退件',
    displayAgent: defineDisplay('遞件修訂', 'border_color', '#D32F2F'),
    displayHolder: defineDisplay('遞件修訂', 'border_color', '#D32F2F'),
    displayDealing: defineDisplay('追蹤修訂', 'visibility', '#D32F2F'),
    editable: defineTransform(TradeOperation.Deliver, TradeStatus.Redelivered), // 編輯後重新遞件
    ...NoReturn,
  },
  {
    code: TradeStatus.FileRejected,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '紙本申請退件',
    displayAgent: defineDisplay('重送正本', 'reply', '#D32F2F'),
    displayHolder: defineDisplay('重送正本', 'reply', '#D32F2F'),
    displayDealing: defineDisplay('重收正本', 'visibility', '#D32F2F'),
    action: defineTransform(TradeAction.ConfirmFile, TradeStatus.FileReceived), // 註記 → 已收到正本
    doneActions: [TradeAction.NotifyFile],
    ...NoReturn,
  },
  {
    code: TradeStatus.Redelivered,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '重新遞件',
    displayAgent: defineDisplay('重新遞件', 'outgoing_mail', '#66BB6A'),
    displayHolder: defineDisplay('重新遞件', 'outgoing_mail', '#66BB6A'),
    displayDealing: defineDisplay('修訂待審', 'edit_note', '#66BB6A'),
    action: defineTransform(TradeOperation.Approve, 'PREVIOUS_STATUS'),
    ...CanReturnOrCancel,
  },
]

const TradeStatusV221: TradeStatusDefinition[] = [
  {
    code: TradeStatus.FaxSent,
    version: SpecVersion.v221,
    context: ['RDM', 'SWITCH'],
    name: '保銀下單',
    displayAgent: defineDisplay('已遞件', 'mark_email_read', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayHolder: defineDisplay('已遞件', 'mark_email_read', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayDealing: defineDisplay('已下單保銀', 'fax', '#FFA726'),
    // action: defineTransform(TradeOperation.Approve, TradeStatus.ReviewPassed), // 審核交易 → 線上審核通過
    action: defineTransform(TradeAction.NotifyFile, TradeStatus.FileWaiting), // 通知寄送正本 v 2.2.1
    ...CanReturnOrCancel,
  },
  {
    code: TradeStatus.CashReceived,
    version: SpecVersion.v221,
    context: ['SBS'],
    name: '已到款',
    displayAgent: defineDisplay('已到款', 'payments', '#2196F3'),
    displayHolder: defineDisplay('已到款', 'payments', '#2196F3'),
    displayDealing: defineDisplay('已到款', 'payments', '#2196F3'),
    // action: defineTransform(TradeOperation.Approve, TradeStatus.ReviewPassed), // 審核交易 → 線上審核通過
    action: defineTransform(TradeAction.NotifyFile, TradeStatus.FileWaiting), // 通知寄送正本 v 2.2.1
    ...CanReturnOrCancel,
  },
]

export const TradeStatusList: TradeStatusDefinition[] = [
  ...TradeStatusReturns,
  {
    code: TradeStatus.Draft,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '草稿',
    displayAgent: defineDisplay('草稿', 'mode', '#9E9E9E'),
    displayHolder: null,
    displayDealing: null,
    editable: defineTransform(TradeOperation.Deliver, TradeStatus.Delivered),
    deletable: defineTransform(TradeOperation.Delete, TradeStatus.Deleted),
    lockActions: 'ALL',
  },
  // 已遞件
  {
    code: TradeStatus.Delivered,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '已遞件',
    displayAgent: defineDisplay('已遞件', 'outgoing_mail', '#66BB6A'),
    displayHolder: defineDisplay('已遞件', 'outgoing_mail', '#66BB6A'),
    displayDealing: defineDisplay('已遞件', 'outgoing_mail', '#66BB6A'),
    action: defineTransform(TradeAction.ConfirmFax, TradeStatus.FaxSent), // 註記 → 保銀下單
    ...CanReturnOrCancel,
  },
  // 收到遞件時立即傳真進行下單
  {
    code: TradeStatus.FaxSent,
    version: SpecVersion.v222,
    context: ['SBS'],
    name: '保銀下單',
    displayAgent: defineDisplay('已遞件', 'mark_email_read', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayHolder: defineDisplay('已遞件', 'mark_email_read', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayDealing: defineDisplay('已下單保銀', 'fax', '#FFA726'),
    action: defineTransform(TradeAction.ConfirmCash, TradeStatus.CashReceived, WaitCashReceive), // 審核到款紀錄 → 確認到款
    ...CanReturnOrCancel,
  },
  {
    code: TradeStatus.FaxSent,
    version: SpecVersion.v222,
    context: ['RDM', 'SWITCH'],
    name: '保銀下單',
    displayAgent: defineDisplay('已遞件', 'mark_email_read', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayHolder: defineDisplay('已遞件', 'mark_email_read', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayDealing: defineDisplay('已下單保銀', 'fax', '#FFA726'),
    action: defineTransform(TradeOperation.Approve, TradeStatus.ReviewPassed), // 審核交易 → 線上審核通過
    // action: defineTransform(TradeAction.NotifyFile, TradeStatus.FileWaiting), // 通知寄送正本 v 2.2.1
    ...CanReturnOrCancel,
  },
  {
    code: TradeStatus.CashReceived,
    version: SpecVersion.v222,
    context: ['SBS'],
    name: '已到款',
    displayAgent: defineDisplay('已到款', 'payments', '#2196F3'),
    displayHolder: defineDisplay('已到款', 'payments', '#2196F3'),
    displayDealing: defineDisplay('已到款', 'payments', '#2196F3'),
    action: defineTransform(TradeOperation.Approve, TradeStatus.ReviewPassed), // 審核交易 → 線上審核通過
    // action: defineTransform(TradeAction.NotifyFile, TradeStatus.FileWaiting), // 通知寄送正本 v 2.2.1
    ...CanReturnOrCancel,
  },
  {
    code: TradeStatus.ReviewPassed,
    version: SpecVersion.v222,
    context: ['SBS'],
    name: '線上審核通過:已到款',
    displayAgent: defineDisplay('已到款', 'mark_email_read', '#2196F3', true), // ! 此角度看到的狀態仍在上一動
    displayHolder: defineDisplay('已到款', 'mark_email_read', '#2196F3', true), // ! 此角度看到的狀態仍在上一動
    displayDealing: defineDisplay('線上審核通過', 'check_circle', '#2196F3'),
    action: defineTransform(TradeAction.NotifyFile, TradeStatus.FileWaiting), // 通知寄送正本
    ...NoReturn, // 電子檔剛審核完畢，不可退件
  },
  {
    code: TradeStatus.ReviewPassed,
    version: SpecVersion.v222,
    context: ['RDM', 'SWITCH'],
    name: '線上審核通過:已遞件',
    displayAgent: defineDisplay('已遞件', 'payments', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayHolder: defineDisplay('已遞件', 'payments', '#66BB6A', true), // ! 此角度看到的狀態仍在上一動
    displayDealing: defineDisplay('線上審核通過', 'check_circle', '#2196F3'),
    action: defineTransform(TradeAction.NotifyFile, TradeStatus.FileWaiting), // 通知寄送正本
    ...NoReturn, // 電子檔剛審核完畢，不可退件
  },
  {
    code: TradeStatus.FileWaiting,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '請寄出正本',
    displayAgent: defineDisplay('請寄送正本', 'mark_email_unread', '#D32F2F'),
    displayHolder: defineDisplay('請寄送正本', 'mark_email_unread', '#D32F2F'),
    displayDealing: defineDisplay('待收正本', 'mark_email_unread', '#D32F2F'),
    action: defineTransform(TradeAction.ConfirmFile, TradeStatus.FileReceived), // 註記 → 已收到正本
    ...CanReturnOrCancel,
  },
  {
    code: TradeStatus.FileReceived,
    version: SpecVersion.v222,
    context: ['RDM'],
    name: '已收到正本:贖回',
    displayAgent: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB'),
    displayHolder: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB'),
    displayDealing: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB'),
    action: defineTransform(TradeAction.ConfirmContract, TradeStatus.RefundWaiting, WaitContractNote), // 確認Contract → 贖回款發放通知
    lockActions: [TradeAction.NotifyFile],
    ...NoReturn, // 確認正本與電子檔無誤，不可退件
  },
  {
    code: TradeStatus.FileReceived,
    version: SpecVersion.v222,
    context: ['SBS', 'SWITCH'],
    name: '已收到正本',
    displayAgent: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB'),
    displayHolder: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB'),
    displayDealing: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB'),
    action: defineTransform(TradeAction.ConfirmContract, TradeStatus.Finished, WaitContractNote), // 確認Contract
    lockActions: [TradeAction.NotifyFile],
    ...NoReturn, // 確認正本與電子檔無誤，不可退件
  },
  {
    code: TradeStatus.RefundWaiting,
    version: SpecVersion.v222,
    context: ['RDM'],
    name: '贖回款發放通知',
    displayAgent: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB', true), // ! 此角度看到的狀態仍在上一動
    displayHolder: defineDisplay('已收到正本', 'mark_email_read', '#B39DDB', true), // ! 此角度看到的狀態仍在上一動
    displayDealing: defineDisplay('待發贖回款通知', 'mail', '#A1887F'),
    action: defineTransform(TradeAction.NotifyRefund, TradeStatus.Finished), // 註記 → 已收到正本
    lockActions: [TradeAction.NotifyFile],
    ...CanReturnOrCancel,
  },
  {
    code: TradeStatus.Finished,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '完成',
    displayAgent: defineDisplay('已完成', 'receipt', '#607D8B'),
    displayHolder: defineDisplay('已完成', 'receipt', '#607D8B'),
    displayDealing: defineDisplay('已完成', 'receipt', '#607D8B'),
    doneActions: 'ALL',
    lockActions: [
      TradeAction.NotifyFile,
      TradeAction.NotifyRefund,
    ],
  },
  {
    code: TradeStatus.CancelConfirm,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '註銷申請',
    displayAgent: defineDisplay('請提供註銷正本', 'mail', '#D32F2F'),
    displayHolder: defineDisplay('請提供註銷正本', 'mail', '#D32F2F'),
    displayDealing: defineDisplay('待提供註銷正本', 'mail', '#D32F2F'),
  },
  {
    code: TradeStatus.Cancelled,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '註銷',
    displayAgent: defineDisplay('已註銷', 'cancel', '#BDBDBD'),
    displayHolder: defineDisplay('已註銷', 'cancel', '#BDBDBD'),
    displayDealing: defineDisplay('已註銷', 'cancel', '#BDBDBD'),
  },
  {
    code: TradeStatus.Deleted,
    version: SpecVersion.v222,
    context: ['SBS', 'RDM', 'SWITCH'],
    name: '已刪除',
    displayAgent: defineDisplay('已刪除', 'cancel', '#BDBDBD'),
    displayHolder: defineDisplay('已刪除', 'cancel', '#BDBDBD'),
    displayDealing: defineDisplay('已刪除', 'cancel', '#BDBDBD'),
  },
]

// 測試流程，並設定 doneActions
testFlow('SBS')
testFlow('RDM')
testFlow('SWITCH')

// 使 2.2.1 的狀態也有 doneActions
testFlow('SBS', undefined, undefined, SpecVersion.v221)
testFlow('RDM', undefined, undefined, SpecVersion.v221)
testFlow('SWITCH', undefined, undefined, SpecVersion.v221)

function defineDisplay(name: string, icon: string, color: string, hidden: boolean = false): DisplayBag {
  return { name, icon, color, hidden }
}

function defineTransform(action: TradeAction | TradeOperation, targetStatus: TransformableStatus, waits?: (trade: ITrade) => any): TransformBag {
  return { action, targetStatus, waits }
}

/**
 * 依據目前會員身份給予狀態的顯示資訊
 * @param code 狀態代碼
 * @param member 目前登入會員
 * @returns 如果代碼或是會員未定義將返回 undefined；若是目前身份不顯示此狀態交易則為 null
 */
export function getTradeStatusDisplay(tradeType: TradeType, code: TradeStatus, member?: IMember | null, version = DefaultSpecVersion): TradeStatusDisplay | null | undefined {
  const info = getStatusDef(tradeType, code, version)
  let display: DisplayBag | null = null
  member = member || useMember().value
  if (!info || !member) return undefined
  if (usePrivilege(Privilege.TradeKeyin) && info.displayAgent) {
    display = info.displayAgent
  }
  else if (usePrivilege(Privilege.TradeApprove) && info.displayDealing) {
    display = info.displayDealing
  }
  else if (info.displayHolder) {
    display = info.displayHolder
  }
  if (display) {
    return {
      ...info,
      ...display,
    }
  }
  return null
}

function intersection(array1: any[], array2: any[]) {
  return array1.filter(value => array2.includes(value))
}
/**
 * 查詢交易狀態定義
 */
function getStatusDef(type: TradeType, status: TradeStatus, version = DefaultSpecVersion) {
  const get221 = () => TradeStatusV221.find(({ context, code }) => version === SpecVersion.v221 && context.includes(type) && code === status)
  const getDefault = () => TradeStatusList.find(({ context, code, version }) => version === DefaultSpecVersion && context.includes(type) && code === status)
  if (version === SpecVersion.v221) {
    return get221() ?? getDefault()
  } else {
    return getDefault()
  }
}

/**
 * 查詢特定交易狀態的轉換動作 (主操作)
 */
function getTransform(type: TradeType, status: TradeStatus, version = DefaultSpecVersion) {
  const def = getStatusDef(type, status, version)
  if (def) return def.action
}

function testFlow(type: TradeType, status: TradeStatus = TradeStatus.Delivered, doneActions: (TradeAction | TradeOperation)[] = [], version = DefaultSpecVersion) {
  const def = getStatusDef(type, status, version)
  const trans = getTransform(type, status, version)
  if (!def) return
  // 根據執行過的流程設定 doneActions
  if (def.doneActions instanceof Array) {
    def.doneActions = [...doneActions, ...(def.doneActions || [])]
  } else {
    def.doneActions = doneActions
  }
  if (def && trans && trans.targetStatus != 'PREVIOUS_STATUS') {
    // console.log(def.name)
    // console.log(">", trans.action, '>', trans.targetStatus)
    // console.log()
    testFlow(type, trans.targetStatus, [...doneActions, trans.action], version)
  } else {
    // console.log(def.name)
    // console.log('# FIN')
    // console.log()
  }
}

/**
 * 取得可篩選的狀態清單 ver 2.2.2
 */
export function getTradeStatusFilterOptions(tradeType: TradeType = 'SBS', member: IMember | null): TradeStatusFilterOption[] {
  if (!member) return []
  const list = [
    TradeStatus.Draft,
    TradeStatus.Delivered,
    TradeStatus.Redelivered,
    TradeStatus.ReviewPassed,
    TradeStatus.FaxSent,
    TradeStatus.CashReceived,
    TradeStatus.FileWaiting,
    TradeStatus.FileReceived,
    TradeStatus.RefundWaiting,
    TradeStatus.ApplyRejected,
    TradeStatus.FileRejected,
    TradeStatus.Finished,
    TradeStatus.CancelConfirm,
    TradeStatus.Cancelled,
  ].map(code => getTradeStatusDisplay(tradeType, code, member)).filter(exist => exist) as TradeStatusDisplay[]
  const names = list.map(i => i.name).filter((item, index, arr) => arr.indexOf(item) === index)
  return names.map(name => ({
    name,
    code: list.filter(i => i.name === name).map(i => i.code).join(',')
  }))
}

/** 
 * 篩選可顯示狀態紀錄
 * history.filter(byVisibility('SBS', member))
 */
export function byVisibility(type: TradeType, member: IMember | null) {
  interface IStatusObject {
    status: TradeStatus
  }
  return (obj: IStatusObject) => {
    if (!member) return false
    const def = getTradeStatusDisplay(type, obj.status, member)
    if (!def) return false
    return !def.hidden
  }
}

/** 目前狀態可以上傳付款證明 (認購) */
export function canUploadPaymentProof(status?: TradeStatus): boolean {
  return [
    TradeStatus.Delivered,
    TradeStatus.FaxSent,
  ].includes(status as any)
}

import type { Privilege, MemberGroup } from '@/utils/enum'

/**
 * 判別目前會員是否有特定權限
 * @param content_code 權限代碼，若為陣列則必須全部皆符合
 * 
 * @example
 * 
 * import { Privilege } from '@/lib/privilege'
 * 
 * <div v-if="usePrivilege(Privilege.Assets_Inventory)">
 *   目前庫存
 * </div>
 * 
 */
export function usePrivilege(content_code: Privilege | Privilege[] | MemberGroup | MemberGroup[]): boolean {
  // 如果是陣列，必須符合所有權限
  if (content_code instanceof Array) {
    return content_code.every(c => usePrivilege(c))
  }
  const member = useMember()

  // 檢查 content_code 是 Privilege 的集合
  if (member.value && isPrivilege(content_code)) {
    return ((member.value.privileges || []).findIndex(pv => pv === content_code) != -1)
  }

  // 檢查 content_code 是 MemberGroup 的集合
  if (member.value && isMemberGroup(content_code)) {
    return ((member.value.group || []).indexOf(content_code) != -1)
  }

  return false
}

/**
 * 檢查是否符合任一個權限項目
 * @param content_codes 需要的任何權限陣列
 * @returns 是否符合其中之一
 */
export function hasAnyPrivilege(content_codes: Privilege[]): boolean {
  return content_codes.some(c => usePrivilege(c))
}
